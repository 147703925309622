<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
                        <h4 class="mb-sm-0">Ganti Email</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'investor' }">
                                        Investor
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">Ganti Email</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Edit Email</h4>
                        </div>
                        <div class="card-body">
                            <form class="">

                                <div class="mb-3">
                                    <label class="form-label">Nama</label>
                                    <input v-model="user.name" type="text" disabled class="form-control" />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label">Email</label>
                                    <input v-model="user.email" type="email" class="form-control" />
                                </div>

                                <div class="float-end">
                                    <router-link :to="{ name: 'investor' }" type="button"
                                        class="btn btn-warning waves-effect waves-light me-2"><i
                                            class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                                        Kembali
                                    </router-link>
                                    <button :disabled="load" @click="editEmailWithPassword()"
                                        class="btn btn-info waves-effect waves-light me-2">
                                        <i class="bx font-size-16 align-middle me-2"
                                            :class="{ 'bx-loader bx-spin': load, 'bx-edit': !load }"></i>
                                        Ganti Email & Password
                                    </button>
                                    <button :disabled="load" @click="editEmail()"
                                        class="btn btn-primary waves-effect waves-light">
                                        <i class="bx font-size-16 align-middle me-2"
                                            :class="{ 'bx-loader bx-spin': load, 'bx-edit': !load }"></i>
                                        Ganti Email Saja
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import { endpoint } from '../../host';
import errorService from '../../services/error-service';
import httpService from '../../services/http-service';

export default {
    components: {
    },
    created() {
        let params = this.$route.params;
        this.user.id = params.id;
        this.showUser();
    },

    data() {
        return {
            load: false,
            user: {
                id: null,
                name: null,
                email: null,
                reset_password: 'Y',
                _method: 'PUT',
            },
        };
    },
    methods: {
        async showUser(){
            let res = await httpService.get(endpoint.user + `/show/${this.user.id}`);
            this.user.email = res.data.data.email;
            this.user.name = res.data.data.name;
        },
        
        editEmailWithPassword(){
            this.user.reset_password = 'Y';
            this.submit();
        },
        
        editEmail(){
            this.user.reset_password = 'N';
            this.submit();
        },

        async submit() {
            try {
                this.load = true;
                let res = await httpService.post(
                    endpoint.user + `/change-email/${this.user.id}`,
                    this.user
                );
                this.load = false;
                if (res.status == 200) {
                    Swal.fire({
                        title: "Berhasil",
                        text: "data berhasil disimpan",
                        icon: "success",
                    });
                    this.$router.push({
                        name: "investor",
                        params: {
                        reload: true,
                        },
                    });
                }
            } catch (error) {
                this.load = false;
                errorService.displayError(error);
            }
        },
    },
};
</script>

<style>
</style>